import { createStore, combineReducers } from 'redux';
import defaultReducer from './reducers/DefaultReducer';
import masterContentReducer from './reducers/MasterContentReducer';
import searchContentReducer from './reducers/SearchContentReducer';
import searchResultContentReducer from './reducers/SearchResultContentReducer';
import costSheetContentReducer from './reducers/CostSheetContentReducer';
import costingCalcReducer from './reducers/CostingCalcReducer';
import serviceMarkUpReducer from './reducers/ServiceMarkUpReducer';
import roomRequestReducer from './reducers/RoomRequestReducer';
import customRooomReducer from './reducers/CustomRooomReducer';
import slabCalcReducer from './reducers/SlabCalcReducer';
import slabCostingMasterDataReducer from './reducers/SlabCostingMasterDataReducer';
import daySummaryReducer from './reducers/DaySummaryReducer';
import searchSlabCostingResultReducer from './reducers/SlabCostingResultReducer';
import additionalTransfersMasterDataReducer from './reducers/AdditionalTransfersMasterDataReducer';
import transfersCostCalcReducer from './reducers/TransfersCostCalReducer';
import bookingCalcReducer from './reducers/BookingCalcReducer';
import bookingHeaderDetailsReducer from './reducers/BookingHeaderDetailsReducer';


const rootReducer = combineReducers({
  appContent: defaultReducer,
  searchContent: masterContentReducer,
  itemSearchCriteria: searchContentReducer,
  itemSearchResults: searchResultContentReducer,
  costingSheetContent: costSheetContentReducer,
  costingCalc: costingCalcReducer,
  markUpValues : serviceMarkUpReducer,
  roomRequests : roomRequestReducer,
  customRooms: customRooomReducer,
  slabCalcData : slabCalcReducer,
  slabMasterData : slabCostingMasterDataReducer,
  daySummary : daySummaryReducer,
  slabCostingResults : searchSlabCostingResultReducer,
  addTransferMasterData: additionalTransfersMasterDataReducer,
  transferCalc: transfersCostCalcReducer,
  bookingHeaderDetails: bookingHeaderDetailsReducer,
  bookingCalc: bookingCalcReducer,
});

const store = createStore(rootReducer)
export default store
