const initialState = {
  searchContent: {
    AdultCount: 0,
    AgentCurrencyId: 0,
    AgentId: 0,
    AgentName: "",
    AgentUniqueId: null,
    BaseUrl: "",
    BookingCategoryId: 0,
    BookingId: 0,
    BookingTypeId: 0,
    BookingStatusId: 0,
    ChauffeurGuideCategoryId: 0,
    ChauffeurGuideGradeId: 0,
    ChauffeurGuideLanguageId: 0,
    ChauffeurGuideSpecialityId: 0,
    ChildCount: 0,
    CostSheetName: "",
    CostSheetReference: "",
    CostSheetUniqueId: "",
    CostingVersionReference: "",
    CreatedBy: "",
    CurrencyId: 0,
    CustomerGroup: "",
    CustomerId: 0,
    ExchangeRate: 0, //default 
    Id: null,
    IsChauffeurGuidePresent: false,
    IsLoadTransportation: false,
    IsSearchPerformed: false,
    IsShowTransportation: false,
    IsTransferRemove: false,
    IsVatRegistered: false,
    MaxPaxCount: 0,
    MinPaxCount: 0,
    NationalGuideCategoryId: 0,
    NationalGuideCount: 0,
    NationalGuideGradeId: 0,
    NationalGuideLanguageId: 0,
    NationalGuideOptionsId: 1,
    NationalGuideSpecialityId: 0,
    PaxOptionId: 0,
    ProductBookingReference: "",
    Rate: 0,
    SellTaxRate: null,
    SheetEndDate: null,
    SheetId: null,
    SheetStartDate: null,
    SourceId: 0,
    StartDate: "",
    TourLeaderCount: 0,
    TourLeaderOptionsId: 1,
    TourLeaderRoomTypeId: 0,
    VersionId: null
  },
};

function masterContentReducer(state = initialState.searchContent, action) {
  switch (action.type) {
    case "UPDATE_MASTER_INPUT":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };

    case "UPDATE_MASTER_OBJECT":
      return {
        ActionName: action.payload.ActionName,
        AdultCount: action.payload.AdultCount,
        AgentCurrencyId: action.payload.AgentCurrencyId,
        AgentId: action.payload.AgentId,
        AgentName: action.payload.AgentName,
        AgentUniqueId: action.payload.AgentUniqueId,
        BaseUrl: action.payload.BaseUrl,
        BookingCategoryId: action.payload.BookingCategoryId,
        BookingId: action.payload.BookingId,
        BookingStatusId: action.payload.BookingStatusId,
        BookingTypeId: action.payload.BookingTypeId,
        ChauffeurGuideCategoryId: action.payload.ChauffeurGuideCategoryId,
        ChauffeurGuideGradeId: action.payload.ChauffeurGuideGradeId,
        ChauffeurGuideLanguageId: action.payload.ChauffeurGuideLanguageId,
        ChauffeurGuideSpecialityId: action.payload.ChauffeurGuideSpecialityId,
        ChildCount: action.payload.ChildCount,
        CostSheetName: action.payload.CostSheetName,
        CostSheetReference: action.payload.CostSheetReference,
        CostSheetUniqueId: action.payload.CostSheetUniqueId,
        CostingVersionReference: action.payload.CostingVersionReference,
        CreatedBy: action.payload.CreatedBy,
        CurrencyId: action.payload.CurrencyId,
        CustomerGroup: action.payload.CustomerGroup,
        CustomerId: action.payload.CustomerId,
        ExchangeRate: action.payload.ExchangeRate,
        Id: action.sheetId,
        IsChauffeurGuidePresent: action.payload.IsChauffeurGuidePresent,
        IsLoadTransportation: action.payload.IsLoadTransportation,
        IsSearchPerformed: action.payload.IsSearchPerformed,
        IsShowTransportation: action.payload.IsShowTransportation,
        IsTransferRemove: action.payload.IsTransferRemove,
        IsVatRegistered: action.payload.IsVatRegistered,
        MaxPaxCount: action.payload.MaxPaxCount,
        MinPaxCount: action.payload.MinPaxCount,
        NationalGuideCount: action.payload.NationalGuideCount,
        NationalGuideCategoryId: action.payload.NationalGuideCategoryId,
        NationalGuideGradeId: action.payload.NationalGuideGradeId,
        NationalGuideLanguageId: action.payload.NationalGuideLanguageId,
        NationalGuideOptionsId: action.payload.NationalGuideOptionsId,
        NationalGuideSpecialityId: action.payload.NationalGuideSpecialityId,
        PaxOptionId: action.payload.PaxOptionId,
        ProductBookingReference: action.payload.ProductBookingReference,
        Rate: action.payload.Rate,
        SheetEndDate: action.payload.SheetEndDate,
        SheetId: action.payload.SheetId,
        SheetStartDate: action.payload.SheetStartDate,
        SourceId: action.payload.SourceId,
        StartDate: action.payload.StartDate,
        TourLeaderCount: action.payload.TourLeaderCount,
        TourLeaderOptionsId: action.payload.TourLeaderOptionsId,
        TourLeaderRoomTypeId: action.payload.TourLeaderRoomTypeId,
        VersionId: action.payload.VersionId
      };
    default:
      return state;
  }
}

export default masterContentReducer;
