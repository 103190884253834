const initialState = {
    slabCostingResults: {
        ServiceUnits: [],
        VehicleRates: [],
        ServiceUnitsOptions: [],
    }
};

function searchSlabCostingResultReducer(state = initialState.slabCostingResults, action) {
    switch (action.type) {
        case "ADD_SLAB_TRANSFER":
            const vehicleRatesObject = {
                Id: action.payload.Id || generateUniqueId(),
                ...action.payload,
            };
            const sortedVehicleRateList = [...state.VehicleRates, vehicleRatesObject].sort((a, b) => a.PaxCount - b.PaxCount);
            return {
                ...state,
                VehicleRates: sortedVehicleRateList,
            };
        case "ADD_SERVICE_UNITS":
            return {
                ...state,
                ServiceUnits: [...state.ServiceUnits, action.payload],
            };
        case "ADD_SERVICE_UNITS_OPTIONS":
            return {
                ...state,
                ServiceUnitsOptions: action.payload,
            };
        case "CLEAR_SLAB_COSTING":
            return {
                ...state,
                VehicleRates: [],
            };
        case "CLEAR_SERVICE_UNITS":
            return {
                ...state,
                ServiceUnits: [],
            };
        default:
            return state;
    }
};

function generateUniqueId() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export default searchSlabCostingResultReducer;

