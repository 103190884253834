const initialState = {
  searchResults: {
    Hotels: [],
    Services: [],
  },
};

function searchResultContentReducer(state = initialState.searchResults, action) {
  switch (action.type) {
    case "ADD_HOTEL":
      return {
        ...state,
        Hotels: addAndSortItems(state.Hotels, action.payload, sortHotels),
      };
    case "ADD_SERVICE":
      return {
        ...state,
        Services: addAndSortItems(state.Services, action.payload, sortServices),
      };
    case "CLEAR_ALL":
      return {
        Hotels: [],
        Services: [],
      };
    default:
      return state;
  }
}

function addAndSortItems(list, newItem, sortFunction) {
  const updatedList = [...list, newItem];
  return updatedList.map((item) => ({
    ...item,
    mappedUnitResults: sortFunction(item?.MappedUnitResults || []),
  }));
}

function sortHotels(mappedUnitResults) {
  return mappedUnitResults.sort((a, b) => {
    const aContractBuyTotal = a.SingleRoom.ContractBuy.Total;
    const bContractBuyTotal = b.SingleRoom.ContractBuy.Total;
    return aContractBuyTotal - bContractBuyTotal;
  });
}

function sortServices(mappedServiceResults) {
  return mappedServiceResults.sort((a, b) => {
    const aContractBuyTotalAdult = a.AdultCost.ContractBuy.Total;
    const bContractBuyTotalAdult = b.AdultCost.ContractBuy.Total;
    return aContractBuyTotalAdult - bContractBuyTotalAdult;
  });
}

export default searchResultContentReducer;
