const initialState = {
    SlabItems: [],
};

const slabCalcReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_SLABDATA':
            const slabItemList = []
            action.payload.forEach(item => {
                slabItemList.push({
                    Id: generateUniqueId(),
                    ...item,
                });
            });
            let itemList = slabItemList.sort((a, b) => a.PaxCount - b.PaxCount);
            return { SlabItems: itemList };

        case 'UPDATE_SLABITEM_LIST':

            return { SlabItems: action.payload };

        case 'UPDATE_SLABDATA':

            const { slabOjectId, slabProperty, value } = action.payload;
            const updatedList = state.SlabItems.map((obj) => {
                if (obj.Id === slabOjectId) {
                    return { ...obj, [slabProperty]: value };
                } else {
                    return obj;
                }
            });

            return { SlabItems: updatedList };

        case 'UPDATE_BOOKING_SLAB':

            const { slabId, property, selected } = action.payload;
            const updatedSlabs = state.SlabItems.map((obj) => {
                if (obj.Id === slabId) {
                    if (selected) {
                        return { ...obj, [property]: selected };
                    } else {
                        return obj;
                    }
                } else {
                    return { ...obj, [property]: false };
                }
            });

            return { SlabItems: updatedSlabs };

        case 'UPDATE_VEHICLE_TYPE':

            const { objectId, vehicleType } = action.payload;
            const items = state.SlabItems.map((obj) => {
                if (obj.Id === objectId) {
                    return {
                        ...obj,
                        VehicleType: vehicleType.label,
                        VehicleTypeId: vehicleType.value !== undefined ? parseInt(vehicleType.value) : 0,
                        VehicleBuyingRate: vehicleType.rate !== undefined ? parseFloat(vehicleType.rate) : 0,
                        MaxPax: vehicleType.max !== undefined ? parseInt(vehicleType.max) : 1,
                        NumberOfVehicles : Math.ceil(parseInt(obj.PaxForTransfer) / (vehicleType.max !== undefined ? parseInt(vehicleType.max) : 1)),
                    };
                } else {
                    return obj;
                }
            });

            return { SlabItems: items };

        case 'REMOVE_SLAB_LIST':
            return { SlabItems: [] };

        default:
            return state;
    }
};

function generateUniqueId() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export default slabCalcReducer;