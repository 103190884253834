const initialState = {
    markUpValues: {
        fnBFixedMarkUp: 0,
        fnBPercentagMarkUp: 0,
        entranceFixedMarkUp: 0,
        entrancePercentagMarkUp: 0,
        transportExpensFixedMarkUp: 0,
        transportExpensPercentagMarkUp: 0,
        jeepTransFixedMarkUp: 0,
        jeepTransPercentagMarkUp: 0,
        activitiesFixedMarkUp: 0,
        activitiesPercentagMarkUp:0,
        othersFixedMarkUp: 0,
        othersPercentagMarkUp: 0,
    },
  };
  
  function serviceMarkUpReducer(state = initialState.markUpValues, action) {
    switch (action.type) {
      case "UPDATE_MARKUPVALUE":
        return {
          ...state,
          [action.payload.name]: action.payload.value,
        };
      default:
        return state;
    }
  }
  
  export default serviceMarkUpReducer;