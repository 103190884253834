const initialState = {
    VehicleTotalCost: [
        {
            VehicleId: '',
            TotalCost: 0,
            TotalMarkup: 0,
        }
    ],
    VeicleTotalDistance: [
        {
            VehicleId: '',
            TotalDistance: 0,
        }
    ],
};

const transfersCostCalcReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_TRANSFER_COSTCALCDATA":
            return { TotalCost: action.payload };

        case "ADD_OR_UPDATE_VEHICLE_WISE_TRANSFERS_COSTCALCDATA":
            const { vehicleId, totalCost, totalMarkup } = action.payload;

            const index = state.VehicleTotalCost.findIndex(
                (data) => data.VehicleId === vehicleId
            );
            return {
                ...state,
                VehicleTotalCost: index === -1
                    ? [...state.VehicleTotalCost, { VehicleId: vehicleId, TotalCost: totalCost, TotalMarkup: totalMarkup }]
                    : state.VehicleTotalCost.map(data =>
                        data.VehicleId === vehicleId ? { ...data, TotalCost: totalCost, TotalMarkup: totalMarkup } : data
                    ),
            };

        case "ADD_OR_UPDATE_VEHICLE_WISE_TOTAL_DISTANCE":
            const { id, totalDistance } = action.payload;

            const matchingIdx = state.VeicleTotalDistance.findIndex(
                (data) => data.VehicleId === id
            );

            return {
                ...state,
                VeicleTotalDistance: matchingIdx === -1
                    ? [...state.VeicleTotalDistance, { VehicleId: id, TotalDistance: totalDistance }]
                    : state.VeicleTotalDistance.map(data =>
                        data.VehicleId === id ? { ...data, TotalDistance: totalDistance } : data
                    ),
            };

        case "DELETE_COST_AND_DISTANCE_BY_VEHICLE_ID":
            const { deletedVehicleId } = action.payload;
            return {
                VehicleTotalCost: state.VehicleTotalCost.filter(
                    (item) => item.VehicleId !== deletedVehicleId
                ),
                VeicleTotalDistance: state.VeicleTotalDistance.filter(
                    (item) => item.VehicleId !== deletedVehicleId
                ),
            };

        case "CLEAR_ALL_VEHICLE_COST_AND_DISTANCE":
            return {
                VehicleTotalCost: initialState.VehicleTotalCost,
                VeicleTotalDistance: initialState.VeicleTotalDistance,
            };

        default:
            return state;
    }
};

export default transfersCostCalcReducer;