const initialState = {
    
};

const roomRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_ROOM_REQUEST':

            return { RoomRequests: action.payload };

        default:
            return state;
    }
};

export default roomRequestReducer;