const initialState = {
    CustomRooms: []
};

const customRoomsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_CUSTOM_ROOM':
            const newList = [...state.CustomRooms, action.payload];
            return {
                ...state,
                CustomRooms: newList,
            };
        case 'DELETE_CUSTOM_ROOM':
            const filteredList = state.CustomRooms.filter(room => room.RoomId != action.payload);
            return {
                ...state,
                CustomRooms: filteredList,
            };
        default:
            return state;
    }
};

export default customRoomsReducer;