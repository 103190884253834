const initialState = {
  IsLocked: false,
  Status: "",
  IsAllowEdit: false,
}

const canEditCostingFields = (state) => {
  // If the booking is completed (status is 'Completed') and it is locked,do not allow editing
  if (state.Status === 'Complete' && state.IsLocked) {
    return true;
  }
  // Otherwise, allow editing
  return false;
};

const bookingHeaderDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_BOOKING_HEADER':
      const updatedState = {
        ...state,
        ...action.payload,
      };
      updatedState.IsAllowEdit = canEditCostingFields(updatedState);
      return updatedState;

    case 'CLEAR_BOOKING_HEADER':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default bookingHeaderDetailsReducer;