const initialState = {
    sidebarShow: true,
    isCurrentTenantSave: false,
    date: null,
  }
  
  const defaultReducer = (state = initialState, { type, ...rest }) => {
    switch (type) {
      case 'set':
        return { ...state, ...rest }
      case 'SET_CURRENT_TENANT':
        let obj = {...rest}
        return { ...state, isCurrentTenantSave:obj.isCurrentTenantSave} ;
      case 'CHANGED_DATE':
        let dateObj = {...rest}
        return { ...state, date : dateObj.date} ;
      default:
        return state
    }
  }

  export default defaultReducer;