const initialState = {
    costingTotals: {
        BookingCostWithoutMarkup: 0,
        TotalCurrentMarkup : 0,
        AdditionalMarkup : 0,
    }
};

const bookingCalcReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'UPDATE_BOOKINGCALCDATA':
            return {
                ...state,
                costingTotals: {
                    ...state.costingTotals,
                    [action.payload.name]: action.payload.value
                },
            };      
        default:
            return state;
    }
};

export default bookingCalcReducer
