const initialState = {
    itemSearchCriteria: {
      ServiceStartDate: "",
      ServiceId: 0,
      HotelCheckinDate: "",
      HotelId: 0,
      NightCount: 0,
    },
  };
  
  function searchContentReducer(state = initialState.itemSearchCriteria, action) {
    switch (action.type) {
      case "UPDATE_INPUT":
        return {
          ...state,
          [action.payload.name]: action.payload.value,
        };
      default:
        return state;
    }
  }
  
  export default searchContentReducer;