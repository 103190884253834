const initialState = {
    perDayRates: {
        NumberOfDays: 0,
        TotalDistance: 0,
        NationalGuideRate: 0,
        ChauffeurGuideRate: 0,
        DriverBattaRate: 0,
        AssistantBattaRate: 0,
        TransportMarkUpFixed: 0,
        TransportMarkUpPercentage: 0,
        TransportVat: 0,
        RoomMarkUpVtRate: 0,
        SingleFixedMarkUp: 0,
        DoubleFixedMarkUp: 0,
        TripleFixedMarkUp: 0,
        DoubleFixedMarkUpPercentage: 0,
        ChildTransportationCost: 0,
        ChildWithBedMarkUp: 0,
        ChildWithoutBedMarkUp: 0,
        ChildQuotationId: {
            label: "40% & 60%",
            value: 1
        },
        TransportRelatedExpense: 0,
    },
};

const slabCostingMasterDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_SLAB_MASTER_DATA':

            return { perDayRates: action.payload };

        case 'UPDATE_SLAB_MASTER_DATA':
            return {
                ...state,
                perDayRates: {
                    ...state.perDayRates,
                    [action.payload.name]: action.payload.value
                },
            };

        case "UPDATE_ALL_SLAB_MASTER_DATA":
            return {
                ...state,
                perDayRates: {
                    NumberOfDays: action.payload.NumberOfDays,
                    TotalDistance: action.payload.TotalDistance,
                    NationalGuideRate: action.payload.NationalGuideRate,
                    ChauffeurGuideRate: action.payload.ChauffeurGuideRate,
                    DriverBattaRate: action.payload.DriverBattaRate,
                    AssistantBattaRate: action.payload.AssistantBattaRate,
                    TransportMarkUpFixed: action.payload.TransportMarkUpFixed,
                    TransportMarkUpPercentage: action.payload.TransportMarkUpPercentage,
                    TransportVat: action.payload.TransportVat,
                    RoomMarkUpVtRate: action.payload.RoomMarkUpVtRate,
                    SingleFixedMarkUp: action.payload.SingleFixedMarkUp,
                    DoubleFixedMarkUp: action.payload.DoubleFixedMarkUp,
                    TripleFixedMarkUp: action.payload.TripleFixedMarkUp,
                    DoubleFixedMarkUpPercentage: action.payload.DoubleFixedMarkUpPercentage,
                    ChildTransportationCost: action.payload.ChildTransportationCost,
                    ChildWithBedMarkUp: action.payload.ChildWithBedMarkUp,
                    ChildWithoutBedMarkUp: action.payload.ChildWithoutBedMarkUp,
                    ChildQuotationId: action.payload.ChildQuotationId,
                    TransportRelatedExpense: action.payload.TransportRelatedExpense,
                }
            };

        default:
            return state;
    }
}
export default slabCostingMasterDataReducer;