const initialState = {
  costSheet: {
    AccomodationList: [],
    ServiceList: [],
    TransportationList: [],
    AdditionalTransferList: [],
  },
};

const costSheetContentReducer = (state = initialState.costSheet || {}, action) => {
  switch (action.type) {
    case "ADD_ACCOMODATION":
      return addOrUpdateItem(state, "AccomodationList", action.payload);

    case "ADD_ACCOMODATION_LIST":
      const updatedAccommodationList = action.payload.map(accommodation => {
        return {
          ...accommodation,
          TourLeaderSupplements: updateSupplementIds(accommodation.TourLeaderSupplements || []),
          ChildWithBedSupplements: updateSupplementIds(accommodation.ChildWithBedSupplements || []),
          ChildWithoutBedSupplements: updateSupplementIds(accommodation.ChildWithoutBedSupplements || []),
          TripleRoomSupplements: updateSupplementIds(accommodation.TripleRoomSupplements || []),
          DoubleRoomSupplements: updateSupplementIds(accommodation.DoubleRoomSupplements || []),
          SingleRoomSupplements: updateSupplementIds(accommodation.SingleRoomSupplements || [])
        };
      });

      return {
        ...state,
        AccomodationList: updatedAccommodationList,
      };

    case "UPDATE_ACCOMODATION":
      return updateItem(state, "AccomodationList", action.payload);

      case "UPDATE_ACCOMODATION_ROOMTYPE":
        return updateRoomItem(state, "AccomodationList", action.payload);

    case "REMOVE_ACCOMODATION":
      return removeItem(state, "AccomodationList", action.payload.id);

    case "SORT_ACCOMODATION_LIST":
      return sortItems(state, "AccomodationList");

    case "ADD_PACKAGE":
      return addOrUpdateItem(state, "ServiceList", action.payload);

    case "ADD_PACKAGE_LIST":
      return {
        ...state,
        ServiceList: action.payload,
      };

    case "UPDATE_PACKAGE":
      return updateItem(state, "ServiceList", action.payload);

    case "REMOVE_PACKAGE":
      return removeItem(state, "ServiceList", action.payload.id);

    case "SORT_PACKAGE_LIST":
      return sortItems(state, "ServiceList");

    case "ADD_TRANSFER":
      return addOrUpdateItem(state, "TransportationList", action.payload);

    case "ADD_TRANSFER_LIST":
      return {
        ...state,
        TransportationList: action.payload,
      };

    case "UPDATE_TRANSFER":
      return updateItem(state, "TransportationList", action.payload);

    case "UPDATE_TRANSFER_ITEM_ROW":
      return updateRowItems(state, "TransportationList", action.payload);

    case "REMOVE_TRANSFER":
      return removeItem(state, "TransportationList", action.payload.id);

    case "CLEAR_TRANSFERS":
      return { ...state, TransportationList: [] };

    case "CLEAR_SHEET_CONTENT":
      return {
        AccomodationList: [],
        ServiceList: [],
        TransportationList: [],
        AdditionalTransferList: [],
      };

    case "ADD_WAYPOINT":
      return addWaypoint(state, "TransportationList", action.payload);

    case "REMOVE_WAYPOINT":
      return removeWaypoint(state, "TransportationList", action.payload);

    case "UPDATE_ADDITIONAL_TRANSFER_LIST_BY_PRIMARY_TRANSFERS":
      const sortedAdditionalTransfers = [
        ...state.AdditionalTransferList.filter((transfer) => transfer.IsAdditional === true),
        ...action.payload
      ].sort((a, b) => a.Day - b.Day);
      return {
        ...state,
        AdditionalTransferList: sortedAdditionalTransfers
      };

    case "ADD_ADDITIONAL_TRANSFER_LIST":
      return {
        ...state,
        AdditionalTransferList: action.payload,
      };

    case "ADD_ADDITIONAL_TRANSFER":
      return addOrUpdateItem(state, "AdditionalTransferList", action.payload);

    case "UPDATE_ADDITIONAL_TRANSFER":
      return updateItem(state, "AdditionalTransferList", action.payload);

    case "UPDATE_ADDITIONAL_TRANSFER_ALL_PROPS":
      //const { additionalTransferItemId, additionalTransferData } = action.payload;
      return {
        ...state,
        AdditionalTransferList: state.AdditionalTransferList?.map((obj) =>
          obj.Id === additionalTransferItemId ? { ...obj, ...additionalTransferData } : obj
        ),
      };

    case "REMOVE_ADDITIONAL_TRANSFER":
      return removeItem(state, "AdditionalTransferList", action.payload.id);

    case "CLEAR_ADDITIONAL_TRANSFER_FROM_PRIMARY":
      const filteredAdditionalTransfers = state.AdditionalTransferList?.filter(
        (trnasfer) => trnasfer.IsAdditional === true
      );
      return {
        ...state,
        AdditionalTransferList: filteredAdditionalTransfers,
      };

    case "CLEAR_ALL_ADDITIONAL_TRANSFERS":
      return { ...state, AdditionalTransferList: [] };

    case "ADD_ADDITIONAL_TRNASFER_WAYPOINT":
      return addWaypoint(state, "AdditionalTransferList", action.payload);

    case "REMOVE_ADDITIONAL_TRNASFER_WAYPOINT":
      return removeWaypoint(state, "AdditionalTransferList", action.payload);

    case "UPDATE_ROOM_SUPPLEMENT":
      return updateRoomSupplement(state, action);

    case "UPDATE_ROOM_DISCOUNT":
      return updateRoomDiscount(state, action);

    case "UPDATE_MARKUP_SUPPLEMENT":
      return updateMarkUpRoomSupplement(state, action);

    case "UPDATE_INCLUDE_FOR_ADULT_OR_CHILD_SERVICE_SUPPLEMENT":
      return updateIncludeAdultOrChildServiceSupplement(state, action);
    case "UPDATE_INCLUDE_FOR_ADULT_AND_CHILD_SERVICE_SUPPLEMENT":
      return updateIncludeAdultAndChildServiceSupplement(state, action);
    case "UPDATE_SERVICE_SUPPLEMENT":
      return updateServiceSupplement(state, action);
    case "UPDATE_INCLUDE_FOR_GUEST_AND_CHILD_AND_TOUR_LEADER_HOTEL_SUPPLEMENT":
      return updateIncludedForHotelSupplement(state, action, [
        "SingleRoomSupplements",
        "DoubleRoomSupplements",
        "TripleRoomSupplements",
        "ChildWithBedSupplements",
        "ChildWithoutBedSupplements",
        "TourLeaderSupplements",
      ]);
    case "UPDATE_INCLUDE_FOR_CHILD_HOTEL_SUPPLEMENT":
      return updateIncludedForHotelSupplement(state, action, [
        "ChildWithBedSupplements",
        "ChildWithoutBedSupplements",
      ]);
    case "UPDATE_INCLUDE_FOR_GUEST_AND_TOUR_LEADER_HOTEL_SUPPLEMENT":
      return updateIncludedForHotelSupplement(state, action, [
        "SingleRoomSupplements",
        "DoubleRoomSupplements",
        "TripleRoomSupplements",
        "TourLeaderSupplements",
      ]);
    case "UPDATE_INCLUDE_DISCOUNT":
      return updateIncludedForDiscount(state, action);
    case "ADD_NEW_VEHICLE":
      const { name, primaryTransfers } = action.payload;
      // Ensure that primaryTransfers is an iterable
      const additionalTransfers = Array.isArray(primaryTransfers) ? [...primaryTransfers] : [];

      // Map over additionalTransfers to add unique TransferItemId
      const primaryTransfersWithId = additionalTransfers.map((item) => ({
        TransferItemId: generateUniqueId(),
        ...item,
      }));

      const newVehicle = {
        Id: generateUniqueId(), // Generate a unique ID
        Name: name,
        AdditionalTransferItemList: primaryTransfersWithId,
      };

      return {
        ...state,
        AdditionalTransferList: [...state.AdditionalTransferList, newVehicle],
      };

    case "SET_ALL_VEHICLE_TRANSFER_LIST":
      return {
        ...state,
        AdditionalTransferList: action.payload,
      }

    case "REMOVE_VEHICLE_BY_ID":
      const { removeVehicleId } = action.payload;
      const removedVehicleUpdatedTransferList = state.AdditionalTransferList.filter(
        (vehicle) => vehicle.Id !== removeVehicleId
      );

      return {
        ...state,
        AdditionalTransferList: removedVehicleUpdatedTransferList,
      };

    case "ADD_NEW_VEHICLE_TRANSFER_ITEM":
      const { additionalTransferVehicleIdToAddItem, newItem, } = action.payload;

      // Find the AdditionalTransferList element by ID and add an item to its AdditionalTransferItemList
      const updatedAdditionalTransferListWithNewItem = state.AdditionalTransferList.map(
        (veheicle) => ({
          ...veheicle,
          AdditionalTransferItemList: veheicle.Id === additionalTransferVehicleIdToAddItem
            ? [...veheicle.AdditionalTransferItemList, { Id: generateUniqueId(), TransferItemId: generateUniqueId(), ...newItem }]
            : veheicle.AdditionalTransferItemList,
        }));

      return {
        ...state,
        AdditionalTransferList: updatedAdditionalTransferListWithNewItem,
      };

    case "UPDATE_ALL_VEHICLE_TRANSFER_LIST_BY_PRIMARY_TRANSFERS":
      // Map over action.payload to add unique TransferItemId
      const updatedPayload = action.payload.map((item) => ({
        TransferItemId: generateUniqueId(),
        ...item // Include the rest of the item properties
      }));

      const updatedAdditionalTransferList = state.AdditionalTransferList.map((veheicle) =>
        veheicle.AdditionalTransferItemList && Array.isArray(veheicle.AdditionalTransferItemList)
          ? {
            ...veheicle,
            AdditionalTransferItemList: [
              ...veheicle.AdditionalTransferItemList.filter(transfer => transfer.IsAdditional === true),
              ...updatedPayload,
            ].sort((a, b) => a.Day - b.Day)
          }
          : veheicle
      );

      return {
        ...state,
        AdditionalTransferList: updatedAdditionalTransferList,
      };

    case "UPDATE_A_VEHICLE_TRANSFER_LIST_BY_PRIMARY_TRANSFERS":
      const { updatedVehicleId, primaryTransferList } = action.payload;
      // Map over action.payload to add unique TransferItemId
      const updatedPrimaryTransferList = primaryTransferList.map((item) => ({
        TransferItemId: generateUniqueId(),
        ...item // Include the rest of the item properties
      }));

      const updatedAdditionalTransferListByPrimaryList = state.AdditionalTransferList.map((veheicle) =>
        veheicle.Id === updatedVehicleId && veheicle.AdditionalTransferItemList && Array.isArray(veheicle.AdditionalTransferItemList)
          ? {
            ...veheicle,
            AdditionalTransferItemList: [
              ...veheicle.AdditionalTransferItemList.filter(transfer => transfer.IsAdditional === true),
              ...updatedPrimaryTransferList,
            ].sort((a, b) => a.Day - b.Day)
          }
          : veheicle
      );

      return {
        ...state,
        AdditionalTransferList: updatedAdditionalTransferListByPrimaryList,
      };

    case "UPDATE_ADDITIONAL_TRANSFER_ITEM_PROPERTY":
      const { id, transferId, propertyName, propetyValue } = action.payload;

      const updatedTransferList = state.AdditionalTransferList.map(
        (veheicle) => veheicle.Id === id && veheicle.AdditionalTransferItemList
          ? {
            ...veheicle,
            AdditionalTransferItemList: veheicle.AdditionalTransferItemList.map((transfer) =>
              transfer.Id === transferId ? { ...transfer, [propertyName]: propetyValue } : transfer
            ),
          }
          : veheicle);

      return {
        ...state,
        AdditionalTransferList: updatedTransferList
      };

    case "UPDATE_ALL_PROPERTY_OF_SELECTED_VEHICLE_TRANSFER_ITEM":
      const { additionlaTransferVehicleId, additionalTransferItemId, additionalTransferData } = action.payload;

      const updatedAllPropsVehicleTransferItem = state.AdditionalTransferList.map(
        (vehicle) => vehicle.Id === additionlaTransferVehicleId
          ? {
            ...vehicle,
            AdditionalTransferItemList: vehicle.AdditionalTransferItemList
              .map((item) =>
                item.Id === additionalTransferItemId ? { ...item, ...additionalTransferData } : item
              )
              .sort((a, b) => a.Day - b.Day),
          }
          : vehicle
      );

      return {
        ...state,
        AdditionalTransferList: updatedAllPropsVehicleTransferItem,
      };

    case "REMOVE_ITEM_FROM_ADDITIONAL_TRANSFER_ITEM_LIST":
      const { vehicleId, itemId } = action.payload;

      const updatedVehicleTransferList = state.AdditionalTransferList.map((item) =>
        item.Id === vehicleId && item.AdditionalTransferItemList
          ? { ...item, AdditionalTransferItemList: item.AdditionalTransferItemList.filter((transfer) => transfer.Id !== itemId) }
          : item
      );

      return {
        ...state,
        AdditionalTransferList: updatedVehicleTransferList,
      };

    case "ADD_WAYPOINT_OF_VEHICLE_TRANSFER_ITEM":
      const { waypointVehicleId, waypointItemId, waypoint } = action.payload;

      const updatedWaypointTransferList = state.AdditionalTransferList.map((item) =>
        item.Id === waypointVehicleId && item.AdditionalTransferItemList
          ? {
            ...item,
            AdditionalTransferItemList: item.AdditionalTransferItemList.map((transfer) =>
              transfer.Id === waypointItemId
                ? { ...transfer, WayPointObjList: [...transfer.WayPointObjList, waypoint] }
                : transfer
            ),
          }
          : item
      );

      return {
        ...state,
        AdditionalTransferList: updatedWaypointTransferList,
      };

    case "REMOVE_WAYPOINT_OF_VEHICLE_TRANSFER_ITEM":
      const { waypointRemoveVehicleId, waypointRemoveItemId, removeWaypointValue } = action.payload;

      const updatedRemovalWapointTransferList = state.AdditionalTransferList.map((item) =>
        item.Id === waypointRemoveVehicleId && item.AdditionalTransferItemList
          ? {
            ...item,
            AdditionalTransferItemList: item.AdditionalTransferItemList.map((transfer) =>
              transfer.Id === waypointRemoveItemId
                ? {
                  ...transfer,
                  WayPointObjList: transfer.WayPointObjList.filter(
                    (waypoint) => waypoint.value !== removeWaypointValue.value
                  ),
                }
                : transfer
            ),
          }
          : item
      );

      return {
        ...state,
        AdditionalTransferList: updatedRemovalWapointTransferList,
      };

    case "UPDATE_INDIVIDUAL_ROOM_HOTEL_AMEND":
      return updateIndividualHotelRoomDetails(state, action);
    case "UPDATE_ALL_ROOM_HOTEL_AMEND":
      return updateAllHotelRoomDetails(state, action)
    case "UPDATE_INDIVIDUAL_ROOM_SERVICE_AMEND":
      return updateIndividualServiceRoomDetails(state, action);
    case "UPDATE_ALL_ROOM_SERVICE_AMEND":
      return updateAllServiceRoomDetails(state, action)
    case "ADD_CUSTOM_ROOM_HOTEL_AMEND":
      return addHotelCustomRoom(state, action)
    case "REMOVE_CUSTOM_ROOM_HOTEL_AMEND":
      return removeHotelCustomRoom(state, action)
    case "CLEAR_ROOM_COUNT_ACCOMODATION_AMEND":
      return clearAccomodationRoomCount(state, action)
    case "CLEAR_SERVICES_ROOM_AMEND":
      return clearServicesRoomDetails(state, action)
    case "UPDATE_ALL_ACCOMODATION_REMARK":
      return updateItemRemark(state, "AccomodationList", action.payload);
    case "UPDATE_ALL_SERVICE_REMARK":
      return updateItemRemark(state, "ServiceList", action.payload);
    default:
      return state;
  }
};

function updateSupplementIds(supplements) {
  return supplements.map(supplement => {
    return {
      ...supplement,
      Id: supplement.SupplementId
    };
  });
}

function sortItems(state, listName) {
  const sortedList = state[listName].sort((a, b) => a.Day - b.Day);
  return {
    ...state,
    [listName]: sortedList,
  };
}

function addOrUpdateItem(state, listName, payload) {
  const newObj = {
    Id: generateUniqueId(),
    ...payload,
  };
  const newList = [...state[listName], newObj].sort((a, b) => a.Day - b.Day);
  return {
    ...state,
    [listName]: newList,
  };
}

function updateItem(state, listName, payload) {
  const { objectId, property, value } = payload;
  const newList = state[listName].map((obj) => {
    if (obj.Id === objectId) {
      return { ...obj, [property]: value };
    } else {
      return obj;
    }
  });
  return { ...state, [listName]: newList };
}

function updateRoomItem(state, listName, payload) {
  const { objectId, property, value, supplementTypes } = payload;
  const newList = state[listName].map((obj) => {
    if (obj.Id === objectId) {
      if (supplementTypes.includes(property)) {
        let updatedSupplements = obj[property].map((supplement, index) => {
          if (index === 0) {
            return { ...supplement, Name: value.name, Id: value.id, SupplementId: value.id };
          }
          return value.isLoadSheet ? { ...supplement } : { ...supplement, SupplementId: supplement.Id };
        });
        return { ...obj, [property]: updatedSupplements };
      } else {
        return { ...obj, [property]: value };
      }
    } else {
      return obj;
    }
  });
  return { ...state, [listName]: newList };
}


function updateRowItems(state, listName, payload) {
  const { objectId, itemData } = payload;

  const updatedList = state[listName].map((obj) => {
    if (obj.Id === objectId) {
      return { ...obj, ...itemData };
    }
    return obj;
  });
  updatedList.sort((a, b) =>new Date(a.Date) - new Date(b.Date));

  return { ...state, [listName]: updatedList };
}

function removeItem(state, listName, id) {
  const filteredList = state[listName].filter((obj) => obj.Id !== id);
  return { ...state, [listName]: filteredList };
}

function addWaypoint(state, listName, payload) {
  const { transferId, waypoint } = payload;
  const transfers = state[listName].map((obj) => {
    if (obj.Id === transferId) {
      const waypoints = [...obj.WayPointObjList];
      waypoints.push(waypoint);
      return { ...obj, WayPointObjList: waypoints };
    } else {
      return obj;
    }
  });
  return { ...state, [listName]: transfers };
}

function removeWaypoint(state, listName, payload) {
  const { transferObjId, waypointValue } = payload;
  const transfersList = state[listName].map((obj) => {
    if (obj.Id === transferObjId) {
      const waypoints = obj.WayPointObjList.filter((item) => item !== "");
      if (waypointValue === "") {
        return { ...obj, WayPointObjList: waypoints };
      } else {
        return {
          ...obj,
          WayPointObjList: waypoints.filter(
            (item) => item.value !== waypointValue.value
          ),
        };
      }
    } else {
      return obj;
    }
  });
  return { ...state, [listName]: transfersList };
}

function updateRoomSupplement(state, action) {
  const { hotelObjId, supplementId, total, included, roomType } = action.payload;
  return {
    ...state,
    AccomodationList: state.AccomodationList.map((hotel) => {
      if (hotel.Id === hotelObjId) {
        const roomTypeSupplements = hotel[`${roomType}Supplements`].map(
          (supplement, index) => {
            if (supplement.Id === supplementId) {
              return {
                ...supplement,
                SupplementRate: total,
                Included: included,
              };
            }
            return supplement;
          }
        );
        return {
          ...hotel,
          [`${roomType}Supplements`]: roomTypeSupplements,
        };
      }
      return hotel;
    }),
  };
}


function updateRoomDiscount(state, action) {
  const { hotelObjId, discountId, total, included, roomType } = action.payload;
  return {
    ...state,
    AccomodationList: state.AccomodationList.map((hotel) => {
      if (hotel.Id === hotelObjId) {
        const roomTypeDiscounts = hotel[`${roomType}Discounts`].map(
          (discount, index) => {
            if (discount.DiscountId === discountId) {
              return {
                ...discount,
                DiscountRate: total,
                Included: included,
              };
            }
            return discount;
          }
        );
        return {
          ...hotel,
          [`${roomType}Discounts`]: roomTypeDiscounts,
        };
      }
      return hotel;
    }),
  };
}

function updateMarkUpRoomSupplement(state, action) {
  const { hotelObjId, suppIndex, markUpForSupplement, markupType } = action.payload;
  return {
    ...state,
    AccomodationList: state.AccomodationList.map((hotel) => {
      if (hotel.Id === hotelObjId) {
        return {
          ...hotel,
          SingleRoomSupplements: hotel.SingleRoomSupplements.map(
            (supplement, index) => {
              if (index === suppIndex) {
                return { ...supplement, [markupType]: markUpForSupplement };
              }
              return supplement;
            }
          ),
          DoubleRoomSupplements: hotel.DoubleRoomSupplements.map(
            (supplement, index) => {
              if (index === suppIndex) {
                return { ...supplement, [markupType]: markUpForSupplement };
              }
              return supplement;
            }
          ),
          TripleRoomSupplements: hotel.TripleRoomSupplements.map(
            (supplement, index) => {
              if (index === suppIndex) {
                return { ...supplement, [markupType]: markUpForSupplement };
              }
              return supplement;
            }
          ),
          ChildWithBedSupplements: hotel.ChildWithBedSupplements.map(
            (supplement, index) => {
              if (index === suppIndex) {
                return { ...supplement, [markupType]: markUpForSupplement };
              }
              return supplement;
            }
          ),
          ChildWithoutBedSupplements: hotel.ChildWithoutBedSupplements.map(
            (supplement, index) => {
              if (index === suppIndex) {
                return { ...supplement, [markupType]: markUpForSupplement };
              }
              return supplement;
            }
          ),
        };
      }
      return hotel;
    }),
  };
};

function updateIndividualHotelRoomDetails(state, action) {
  const { hotelId, index, roomCount } = action.payload;
  return {
    ...state,
    AccomodationList: state.AccomodationList.map((hotel) => {
      if (hotelId === hotel.Id) {
        return {
          ...hotel,
          PaxDetails: hotel.PaxDetails.map((pax, idx) => {
            if (index === idx) {
              return { ...pax, RoomCount: roomCount };
            }
            return pax;

          })
        }
      }
      return hotel;
    })
  }
}

function updateIncludeAdultOrChildServiceSupplement(state, action) {
  const { serviceId, supplementType, included, supplementId } = action.payload;
  return {
    ...state,
    ServiceList: state.ServiceList.map((service) => {
      if (service.Id === serviceId) {
        return {
          ...service,
          [supplementType]: service[supplementType].map((supplement, index) => {
            if (supplement.Id === supplementId) {
              return { ...supplement, Included: included };
            }
            return supplement;
          }),
        };
      };
      return service;
    }),
  };
};

function updateIncludeAdultAndChildServiceSupplement(state, action) {
  const { serviceId, included, supplementId } = action.payload;
  return {
    ...state,
    ServiceList: state.ServiceList.map((service) => {
      if (service.Id === serviceId) {
        return {
          ...service,
          AdultSupplements: service.AdultSupplements.map((supplement, index) => {
            if (supplement.Id === supplementId) {
              return { ...supplement, Included: included };
            }
            return supplement;
          }),
          ChildSupplements: service.ChildSupplements.map((supplement, index) => {
            if (supplement.Id === supplementId) {
              return { ...supplement, Included: included };
            }
            return supplement;
          }),
        };
      };
      return service;
    }),
  };
};

function updateServiceSupplement(state, action) {
  const { serviceId, supplementId, total, property, supplementType } = action.payload;
  return {
    ...state,
    ServiceList: state.ServiceList.map((service) => {
      if (service.Id === serviceId) {
        const supplements = service[supplementType].map(
          (supplement, index) => {
            if (supplement.Id === supplementId) {
              return {
                ...supplement,
                [property]: total,
              };
            }
            return supplement;
          }
        );
        return {
          ...service,
          [supplementType]: supplements,
        };
      }
      return service;
    }),
  };
}


function clearAccomodationRoomCount(state, action) {
  return {
    ...state,
    AccomodationList: state.AccomodationList.map((hotel) => ({
      ...hotel,
      PaxDetails: hotel.PaxDetails.filter(room => !room.RoomId).map((room) => ({
        ...room,
        RoomCount: 0
      }))
    })),
  };
}

function clearServicesRoomDetails(state, action) {
  return {
    ...state,
    ServiceList: state.ServiceList.map((service) => ({
      ...service,
      PaxDetails: service.PaxDetails.map((room) => ({
        ...room,
        AdultsCount: 0,
        ChildCount: 0,
        GuideCount: 0,
        TourLeaderCount: 0
      }))
    })),
  };
}



function updateSupplementType(hotel, supplementType, supplementId, included) {
  return {
    ...hotel,
    [supplementType]: hotel[supplementType].map((supplement) => {
      if (supplement.Id === supplementId) {
        return { ...supplement, Included: included };
      }
      return supplement;
    }),
  };
}

function updateDiscountType(hotel, discountType, discountId, groupHash, included) {
  return {
    ...hotel,
    [`${discountType}Discounts`]: hotel[`${discountType}Discounts`].map((discount) => {
      if (discount.DiscountId === discountId && discount.GroupHash === groupHash) {
        return { ...discount, Included: included };
      }
      return discount;
    }),
  };
}


function updateIncludedForHotelSupplement(state, action, supplementTypes) {
  const { hotelId, included, supplementId } = action.payload;
  return {
    ...state,
    AccomodationList: state.AccomodationList.map((hotel) => {
      if (hotel.Id === hotelId) {
        return supplementTypes.reduce((updatedHotel, supplementType) => {
          return updateSupplementType(updatedHotel, supplementType, supplementId, included);
        }, { ...hotel });
      }
      return hotel;
    }),
  };
}

function updateIncludedForDiscount(state, action) {
  const { hotelId, included, discountId, groupHash, discountType } = action.payload;
  return {
    ...state,
    AccomodationList: state.AccomodationList.map((hotel) => {
      if (hotel.Id === hotelId) {
        return updateDiscountType(hotel, discountType, discountId, groupHash, included);;
      }
      return hotel;
    }),
  };
}

function updateAllHotelRoomDetails(state, action) {
  const { index, roomCount } = action.payload;
  return {
    ...state,
    AccomodationList: state.AccomodationList.map((hotel) => {
      return {
        ...hotel,
        PaxDetails: hotel.PaxDetails.map((pax, idx) => {
          if (index === idx) {
            return { ...pax, RoomCount: roomCount };
          }
          return pax;
        })
      }
    })
  }
}

function updateIndividualServiceRoomDetails(state, action) {
  const { serviceId, name, value } = action.payload;
  return {
    ...state,
    ServiceList: state.ServiceList.map((service) => {
      if (serviceId === service.Id) {
        return {
          ...service,
          PaxDetails: service.PaxDetails.map((pax, idx) => {
            return { ...pax, [name]: value };
          })
        }
      }
      return service;
    })
  }
}

function updateAllServiceRoomDetails(state, action) {
  const { name, value } = action.payload;
  return {
    ...state,
    ServiceList: state.ServiceList.map((service) => {
      return {
        ...service,
        PaxDetails: service.PaxDetails.map((pax, idx) => {
          return { ...pax, [name]: value };
        })
      }
    })
  }
}

function addHotelCustomRoom(state, action) {
  const { room } = action.payload;
  return {
    ...state,
    AccomodationList: state.AccomodationList.map((hotel) => {
      return {
        ...hotel,
        PaxDetails: [...hotel.PaxDetails, room],
      }
    })
  }
}

function removeHotelCustomRoom(state, action) {
  const { room } = action.payload;
  return {
    ...state,
    AccomodationList: state.AccomodationList.map((hotel) => {
      const filteredPaxDetails = hotel.PaxDetails.filter(obj => obj.RoomId != room.RoomId);
      return {
        ...hotel,
        PaxDetails: filteredPaxDetails,
      }
    })
  }
}



function updateItemRemark(state, listName, payload) {
  const { remark } = payload;
  const newList = state[listName].map((obj) => {
    return { ...obj, [`Remarks`]: remark };
  });
  return { ...state, [listName]: newList };
}

function generateUniqueId() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default costSheetContentReducer;
