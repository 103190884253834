const initialState = {
    daySummary: {},
}

const daySummaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_DAY_SUMMARY':
            return {
                ...state,
                daySummary: {
                    ...action.payload
                },
            };
        default:
            return state
    }
}

export default daySummaryReducer;