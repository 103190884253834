const initialState = {
    Totals: {},
};

const costingCalcReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_COSTCALCDATA':

            return { Totals: action.payload };

        default:
            return state;
    }
};

export default costingCalcReducer;