const initialState = {
    MasterDataByVehicleId: [
        {
            VehicleId: '',
            PerDayRates: {
                NationalGuideRate: 0,
                ChauffeurGuideRate: 0,
                DriverBattaRate: 0,
                AssistantBattaRate: 0,
                TransportRelatedExpense: 0, //OtherExpensePerVehicle
                TransportMarkUpFixed: 0,
                NumberOfDays: 0,
                TotalDistance: 0,
                NumberOfPax: 0,
                VehicleTypeId: 0,
                VehicleType: "",
                VehicleRate: 0,
                NumberOfVehicle: 0,
                MaxPax:0,
                VehicleContractBuyVatRatio: 0,
                VehicleSystemBuyVatRatio: 0,
            },
        },
    ],
    VehicleTransferShownStatus: [
        {
            VehicleId: '',
            IsShowAdditionalTransfers: false
        },
    ],
};

const additionalTransfersMasterDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_ADDITIONAL_TRANSFER_MASTER_DATA":
            return { masterDataByVehicleId: action.payload };

        case "ADD_OR_UPDATE_VEHICLE_MASTER_DATA_BY_ITEM":
            const { id, field, value } = action.payload;

            // Find the index of the element with the matching vehicle id
            const index = state.MasterDataByVehicleId.findIndex(
                (data) => data.VehicleId === id
            );

            return {
                ...state,
                MasterDataByVehicleId: index === -1
                    ? [...state.MasterDataByVehicleId, { VehicleId: id, PerDayRates: { [field]: value } }]
                    : state.MasterDataByVehicleId.map((data) =>
                        data.VehicleId === id
                            ? { ...data, PerDayRates: { ...data.PerDayRates, [field]: value } }
                            : data
                    ),
            };

        case "ADD_OR_UPDATE_ALL_VEHICLE_MASTER_DATA":
            const { vehicleId, perDayRates } = action.payload;

            const matchingIdx = state.MasterDataByVehicleId.findIndex(
                (item) => item.VehicleId === vehicleId
            );

            return {
                ...state,
                MasterDataByVehicleId: matchingIdx === -1
                    ? [...state.MasterDataByVehicleId, { VehicleId: vehicleId, PerDayRates: perDayRates }]
                    : state.MasterDataByVehicleId.map((item) =>
                        item.VehicleId === vehicleId
                            ? { ...item, PerDayRates: { ...item.PerDayRates, ...perDayRates } }
                            : item
                    ),
            };

        case "SET_ALL_VEHICLE_MASTER_DATA_LIST":
            return {
                ...state,
                MasterDataByVehicleId: action.payload,
            };

        case "ADD_OR_UPDATE_VEHICLE_TRANSFER_SHOWN_STATUS":
            const { transferVehicleId, isShowAdditionalTransfers } = action.payload;
            const existingStatusIndex = state.VehicleTransferShownStatus.findIndex(
                (status) => status.VehicleId === transferVehicleId
            );

            return {
                ...state,
                VehicleTransferShownStatus: existingStatusIndex !== -1
                    ? state.VehicleTransferShownStatus.map((status, index) =>
                        index === existingStatusIndex
                            ? { VehicleId: transferVehicleId, IsShowAdditionalTransfers: isShowAdditionalTransfers }
                            : status
                    )
                    : [...state.VehicleTransferShownStatus, { VehicleId: transferVehicleId, IsShowAdditionalTransfers: isShowAdditionalTransfers }],
            };

        case "SET_ALL_VEHICLE_TRANSFER_SHOWN_STATUS_LIST":
            return {
                ...state,
                VehicleTransferShownStatus: action.payload,
            };

        case "DELETE_MASTER_DATA_AND_SHOWN_STATUS_BY_VEHICLE_ID":
            const { deletedVehicleId } = action.payload;

            return {
                MasterDataByVehicleId: state.MasterDataByVehicleId.filter(
                    (item) => item.VehicleId !== deletedVehicleId
                ),
                VehicleTransferShownStatus: state.VehicleTransferShownStatus.filter(
                    (item) => item.VehicleId !== deletedVehicleId
                ),
            };

        case "CLEAR_VEHICLE_MASTER_DATA_AND_SHOWN_STATUS":
            return {
                MasterDataByVehicleId: initialState.MasterDataByVehicleId,
                VehicleTransferShownStatus: initialState.VehicleTransferShownStatus,
            };

        default:
            return state;
    }
}
export default additionalTransfersMasterDataReducer;